/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import SpeedIcon from '@material-ui/icons/Speed'
import moment from 'moment';
import globalConfigs from '../../../../config/globalConfigs'

const routes = [
  {
    title: 'Páginas',
    pages: [
      {
        title: globalConfigs.infos.storeTitle,
        href: '/loja',
        novo: false,
        icon: ShoppingCartOutlinedIcon,
        children: [
          {
            title: 'Produtos',
            href: '/loja/produtos',
            novo: false,
          },
          {
            title: 'Atributos',
            href: '/loja/atributos',
            novo: false,
          },
          {
            title: 'Marcas',
            href: '/loja/marcas',
            novo: false,
          },
          {
            title: 'Transportadoras',
            href: '/loja/transportadoras',
            novo: false,
          },
          {
            title: 'Categorias',
            href: '/loja/categorias',
            novo: false,
          },
          {
            title: 'Fornecedores',
            href: '/loja/fornecedores',
            novo: false,
          },
          {
            title: 'Estoques',
            href: '/loja/estoques',
            novo: false,
          },
          {
            title: 'Pedidos',
            href: '/loja/pedidos',
            novo: false,
          },
        ],
      },
      {
        title: 'Plataforma',
        href: '/plataforma',
        novo: false,
        icon: LaptopMacIcon,
        children: [
          {
            title: 'Projetos',
            href: '/plataforma/projetos',
            novo: false,
          },
          {
            title: 'Financiamentos',
            href: '/plataforma/financiamentos',
            novo: false,
          },
          {
            title: 'Simulações',
            href: '/plataforma/simulacoes?page=1' + '&initial_date=' + moment().subtract(30, 'days').format('YYYY/MM/DD') + '&final_date=' + moment().format('YYYY/MM/DD'),
            novo: false,
          },
          {
            title: 'Sites',
            href: '/plataforma/sites',
            novo: false,
          },
          {
            title: 'Estoques globais',
            href: '/plataforma/estoques',
            novo: false,
          },
          {
            title: 'Tarifas e Fio B',
            href: '/plataforma/tarifas',
            novo: false,
          },
        ],
      },
      {
        title: 'FaaS',
        href: '/faas',
        novo: false,
        icon: AccountBalanceOutlinedIcon,
        children: [
          {
            title: 'Financiamentos',
            href: '/faas/financiamentos',
            novo: false,
          },
          {
            title: 'Simulações',
            href: '/faas/simulacoes?page=1' + '&initial_date=' + moment().subtract(30, 'days').format('YYYY/MM/DD') + '&final_date=' + moment().format('YYYY/MM/DD'),
            novo: false,
          },
        ],
      },
      {
        title: 'Usuários',
        href: '/usuarios',
        novo: false,
        icon: PeopleOutlineOutlinedIcon,
        children: [
          {
            title: 'Empresas',
            href: '/usuarios/empresas',
            novo: false,
          },
          // @deprecated
          // {
          //   title: 'Liberação de usuário',
          //   href: '/usuarios/liberacao-usuarios',
          //   novo: false,
          // },
        ],
      },
      {
        title: 'SolLeads',
        href: '/sol-leads',
        novo: false,
        icon: WbSunnyOutlinedIcon,
      },
      {
        title: 'Monitoramento',
        href: '/plataforma/monitoramento',
        novo: false,
        icon: SpeedIcon,
      },
      {
        title: 'Configurações',
        href: '/configuracoes',
        novo: false,
        icon: SettingsIcon,
        children: [
          {
            title: 'Parâmetros',
            href: '/configuracoes/parametros',
            novo: false,
          },
          {
            title: 'Perfis de usuários',
            href: '/configuracoes/perfis',
            novo: false,
          },
          {
            title: 'Grupo de usuários',
            href: '/configuracoes/grupo-usuarios',
            novo: false,
          },
          {
            title: 'Configurações de bancos FaaS',
            href: '/configuracoes/faas/bancos',
            novo: false,
          },
        ],
      },
    ],
  },
]

export default routes
